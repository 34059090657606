<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <!-- <tclogin/> -->
    <div class="content">
      <div class="cons" v-loading="loading" element-loading-text="提交中...">
        <div class="sctp-title">《{{ zazhiname }}》杂志社稿件邮件直投</div>
        <div class="sctp">
          <div class="custom-block">请拖拽或选择正文文档上传。</div>
          <el-upload
            drag
            v-model:file-list="fileList"
            class="upload-demo"
            action="#"
            list-type="text"
            :limit="1"
            accept=".doc, .docx,"
            :on-exceed="handleExceed"
            :before-upload="handlePreview"
            :before-remove="handleRemove"
            :http-request="upload"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽文档到此处或 <em>点击此处</em> 选择文档上传
            </div>
            <template #tip>
              <div class="el-upload__tip">只能上传doc或docx格式文档</div>
            </template>
          </el-upload>
        </div>
        <div class="cons-form" v-show="isfrom">
          <el-form :model="form" label-width="120px">
            <div class="custom-block">
              请填写标题、作者、邮箱、电话等相关信息。
            </div>
            <el-form-item label="标题">
              <el-input v-model="form.title" placeholder="请输入标题" />
            </el-form-item>
            <el-form-item label="联系邮箱">
              <el-input v-model="form.email" placeholder="请输入邮箱" />
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="form.phone" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="邀请码" v-show="isyqm">
              <el-input
                v-model="form.ccemail"
                placeholder="请输入邀请码，没有邀请码可不填写"
              />
            </el-form-item>
          </el-form>
        </div>

        <div class="con-btn">
          <el-button type="primary" @click="clickcklb">立即提交</el-button>
        </div>
      </div>

      <div class="tougao-qkjs">
        <div class="tougaoxuzhi">投稿须知</div>
        <div class="tougaoxuzhi-img">
          <el-image
            style="width: 128px; height: 178px; border: 1px solid #eee"
            :src="proxy.$imgurl + '/qikanfmpic/' + obj.pycode + '.jpg'"
          ></el-image>
        </div>
        <div class="tgxq" v-html="obj.kz1"></div>
      </div>
    </div>

    <div class="dalikefu" v-if="iskefu">
      <div class="dalikefu-title">联系客服</div>
      <div class="dalikefu-list">
        <svg
          t="1708938939060"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="10245"
          width="20"
          height="20"
        >
          <path
            d="M68.399765 590.615655c-37.073602 90.765025-43.465533 176.418105-14.062849 191.757941 20.45478 11.505876 53.692423-14.061849 84.374094-60.084355 11.504876 51.135451 42.186547 95.87897 84.373094 132.952572-44.743519 16.619821-74.146204 44.743519-74.146204 75.42519 0 51.135451 79.259149 93.321998 176.418105 93.321997 88.208052 0 161.07627-33.237643 175.138119-77.982162h20.45478C535.009753 990.751357 607.87897 1023.989 696.087023 1023.989c98.436943 0 176.418105-40.908561 176.418104-93.321997 0-30.68167-29.402684-58.806368-74.146203-75.42519 42.186547-37.073602 72.868217-81.817121 84.374094-132.952572 30.68067 46.022506 62.640327 71.589231 84.373093 60.084355 30.68167-15.339835 24.289739-102.270901-14.061849-191.757941-29.403684-70.311245-69.033258-122.725682-99.714929-134.230558 0-3.835959 1.278986-8.949904 1.278987-14.062849 0-26.845712-7.669918-52.413437-20.454781-72.868217v-5.112945c0-12.783863-2.555973-24.289739-7.669917-34.516629C818.813704 145.736434 701.200968 0 510.722014 0 320.24206 0 202.630323 145.736434 194.959406 329.824457c-5.112945 10.22689-7.669918 21.732767-7.669918 34.516629v5.112945c-12.783863 20.45478-20.45478 46.022506-20.45478 72.869217v14.061849c-28.123698 11.504876-69.032258 62.640327-98.434943 134.230558z"
            fill="#4CAFE9"
            p-id="10246"
          ></path>
        </svg>
        <span class="dalikefu-list-sp">QQ：{{ dailiobj.cqq }}</span>
      </div>
      <div class="dalikefu-list">
        <svg
          t="1708938691205"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="8141"
          width="20"
          height="20"
        >
          <path
            d="M512 1.896C229.452 1.896 0 229.452 0 512s229.452 510.104 512 510.104S1022.104 794.548 1022.104 512 794.548 1.896 512 1.896z m-91.022 629.57c-26.548 0-49.304-5.688-75.852-11.377l-75.852 37.926 22.756-66.37c-54.993-37.926-87.23-87.23-87.23-147.912 0-104.296 98.607-185.837 218.074-185.837 108.089 0 201.007 64.474 219.97 153.6-7.585 0-13.274-1.896-20.859-1.896-104.296 0-185.837 77.748-185.837 172.563 0 15.17 1.896 30.34 7.585 45.511-7.585 3.793-15.17 3.793-22.755 3.793z m322.37 77.749l17.067 54.992-58.785-34.133c-22.756 5.689-43.615 11.378-66.37 11.378-104.297 0-185.838-70.163-185.838-157.393S530.963 424.77 635.26 424.77c98.608 0 185.837 70.163 185.837 159.29 0 47.407-32.237 91.021-77.748 125.155z"
            fill="#46BB36"
            p-id="8142"
          ></path>
          <path
            d="M318.578 379.26c0 17.066 13.274 30.34 30.34 30.34s30.341-13.274 30.341-30.34-13.274-30.341-30.34-30.341-30.341 13.274-30.341 30.34z m235.14 159.288c0 13.274 11.378 24.652 24.652 24.652 13.274 0 24.652-11.378 24.652-24.652 0-13.274-11.378-24.652-24.652-24.652-13.274-1.896-24.651 9.482-24.651 24.652z m-81.54-159.289c0 17.067 13.274 30.341 30.34 30.341 17.067 0 30.341-13.274 30.341-30.34 0-17.067-13.274-30.341-30.34-30.341-17.067 0-30.341 13.274-30.341 30.34zM675.08 538.55c0 13.273 11.378 24.651 24.652 24.651 13.274 0 24.652-11.378 24.652-24.652 0-13.274-11.378-24.652-24.652-24.652-13.274-1.896-24.652 9.482-24.652 24.652z"
            fill="#46BB36"
            p-id="8143"
          ></path>
        </svg>
        <span class="dalikefu-list-sp">微信：{{ dailiobj.cwx }}</span>
      </div>

      <a
        class="dalikefu-btns-link"
        :href="
          'tencent://message/?uin=' + dailiobj.cqq + '&Site=在线QQ&Menu=yes'
        "
      >
        <div class="dalikefu-btns">立即QQ联系</div>
      </a>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import COS from "cos-js-sdk-v5";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

// 代理对象
let dailiobj = reactive({});
// 投稿须知
let obj = reactive({});
// 杂志
let zazhiname = ref("");
// loading
let loading = ref(false);
// 是否显示
let isfrom = ref(false);
let isyqm = ref(true);
let iskefu = ref(false);
// 文件列表
let fileList = ref([]);
const form = reactive({
  title: "",
  writer: "",
  email: "",
  phone: "",
  ccemail: "",
  url: "",
  qkid: "",
});
// 上传桶的filename  唯一key
let filename = "";
// 实例
const cos = new COS({
  // getAuthorization 必选参数
  getAuthorization: function (options, callback) {
    // 异步获取临时密钥
    let url =
      "https://titletools-api.yuanpingjia.cn/tougao/getUploadCredential"; // url 替换成您自己的后端服务
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    //发送合适的请求头信息
    xhr.setRequestHeader(
      "Content-Type",
      "application/json-patch+json;charset=UTF-8"
    );
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token") || ""
    );
    xhr.onload = function (e) {
      let resdata, credentials;
      try {
        resdata = JSON.parse(e.target.responseText);
        credentials = resdata.result.credential.credentials;
      } catch (e) {}
      if (!resdata || !credentials) {
        return console.error("credentials invalid");
      }

      callback({
        TmpSecretId: credentials.TmpSecretId,
        TmpSecretKey: credentials.TmpSecretKey,
        SecurityToken: credentials.Token,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: resdata.result.credential.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: resdata.result.credential.expiredTime, // 时间戳，单位秒，如：1580000000
      });
    };
    xhr.send();
  },
});

// 获取期刊详情
function getList() {
  get("/Journal/GetQkInfo", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    zazhiname.value = res.data.Title;
    Object.assign(obj, res.data);
    // console.log(obj)
  });
}
getList();

// 获取链接中的代理并匹配
const dalifun = (dailicode) => {
  get("https://xk-api.yuanpingjia.cn/api/Lw/LwContributeYqminfo", {
    code: dailicode,
  }).then((res) => {
    // console.log(res);
    if (res.data.length > 0) {
      iskefu.value = true;
      Object.assign(dailiobj, res.data[0]);
      // 更新缓存
      window.localStorage.setItem("dalicode", dailicode);
    }
  });
};

const getdaili = () => {
  let dailicode = router.currentRoute.value.query.code;
  if (dailicode) {
    form.ccemail = dailicode;
    isyqm.value = false;
    dalifun(dailicode);
  } else {
    // 获取缓存
    let dailicode = window.localStorage.getItem("dalicode");
    if (dailicode) {
      form.ccemail = dailicode;
      isyqm.value = false;
      dalifun(dailicode);
    }
  }
};
getdaili();

// 上传附件
const upload = (params) => {
  // 自动标题
  form.title = params.file.name.split(".")[0];
  // 文件名不唯一
  filename = params.file.name.split(".")[0] + Date.now();
  isfrom.value = true;
  // form.url =
  //   "https://yuanpingjia-com-cn-1304761361.cos.ap-chongqing.myqcloud.com/tougao/%E5%8A%A0%E5%90%88%E6%8A%95%E7%A8%BF%E7%B3%BB%E7%BB%9F%E4%BB%8B%E7%BB%8D.docx";
  cos.putObject(
    {
      Bucket:
        "yuanpingjia-com-cn-1304761361" /* 填入您自己的存储桶，必须字段 */,
      Region: "ap-chongqing" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
      Key:
        "tougao/" +
        filename +
        "." +
        params.file.name.split(
          "."
        )[1] /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
      Body: params.file,
    },
    function (err, data) {
      if (data.statusCode === 200) {
        form.url = "https://" + data.Location;
      } else {
        ElMessage({
          message: "上传失败：" + err,
          type: "error",
        });
      }
    }
  );
};

// 是否登录
const islogins = () => {
  // 保存当前页面url到本地，以便登录后返回
  window.localStorage.setItem("loginurl", window.location.href);
  let token = window.localStorage.getItem("token");
  if (token && token != "") {
    return true;
  } else {
    ElMessage({
      message: "请登录后再提交",
      type: "error",
      duration: 1000,
      onClose: () => {
        router.push({
          path: "/login",
          query: {},
        });
      },
    });
  }
};

// 上传之前
const handlePreview = (file) => {
  islogins();
  // 此处可以限制大小
  // ElMessage({
  //   message: "图片超出大小",
  //   duration: 1000,
  //   type: 'warning',
  // });
  // return false
};
// 超出限制
const handleExceed = (file) => {
  ElMessage({
    message: "只能上传一个文档",
    duration: 1000,
    type: "warning",
  });
};
// 删除文件
const handleRemove = (uploadFile, uploadFiles) => {
  // pictureUrl.value = "";
};

// 提交信息
const clickcklb = () => {
  form.qkid = router.currentRoute.value.query.id;
  loading.value = true;
  post("https://xk-api.yuanpingjia.cn/api/Lw/LwContribute", form)
    .then((res) => {
      loading.value = false;
      ElMessage({
        message: "提交成功，即将返回首页",
        type: "success",
        duration: 1000,
        onClose: () => {
          router.push({
            path: "/",
            query: {},
          });
        },
      });
    })
    .catch((err) => {
      loading.value = false;
    });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  font-size: 18px;
}
:deep(.con-btn .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.main {
  position: relative;
  background: #fff;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.sctp-info {
  margin-top: 20px;
}
.sctp-title {
  text-align: center;
  font-size: 24px;
  padding-bottom: 20px;
}
.yaoqingma {
  padding: 0 0 0 10px;
  color: #999;
}
.tougao-qkjs {
  position: relative;
  margin: 20px 0 0 0;
  padding: 20px;
  background: #fafafa;
  border: 1px solid #ddd;
}
.tgxq {
  font-size: 14px;
}
.tougaoxuzhi {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
}
.tougaoxuzhi-img {
  position: absolute;
  width: 130px;
  height: 180px;
  right: 20px;
  top: 50px;
}

.dalikefu {
  position: fixed;
  right: 20px;
  top: 400px;
  width: 200px;
  height: auto;
  padding: 20px;
  background: #fafafa;
  border: 1px solid #eee;
  box-shadow: 0 0 10px #ddd;
}
.dalikefu-title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 5px 0;
}
.dalikefu-list {
  width: 100%;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.dalikefu-list-sp {
  padding-left: 10px;
}
.dalikefu-btns {
  width: 100%;
  height: 30px;
  background: #409eff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}
.dalikefu-btns:hover {
  opacity: 0.9;
}
.dalikefu-btns-link {
  text-decoration: none;
}
.dalikefu-btns-link:link,
.dalikefu-btns-link:visited {
  color: #fff;
  text-decoration: none !important;
}
</style>
